module.exports = {
  app: {
    url: "https://fine-day.com",
    domain: "fine-day.com",
  },
  store: {
    siteCurrencyCode: "AUD",
    siteLocation: "AU",
    siteCountries: ["AU"],
    shopName: "fine-day-shop",
    shopNameUrl: "fine-day-shop.myshopify.com",
    shopDomain: "checkout.fine-day.com",
    shopAccessToken: "36e4bcff3abb7cc6f6d626a3ab85a31b",
    shopifyPlus: false,
  },
  services: {
    email: {
      template: "FDAU-WISHLIST-PRD",
      from: "service@fine-day.com",
      service: "postmark",
    },
    firebase: {
      region: "australia-southeast1",
      apiKey: "AIzaSyAofVq9Gm5Y4HZGkBBanxKkY-xZB6aBR_I",
      authDomain: "fineday-website.firebaseapp.com",
      databaseURL: "https://fineday-website.firebaseio.com",
      projectId: "fineday-website",
      storageBucket: "fineday-website.appspot.com",
      messagingSenderId: "495157382203",
      appId: "1:495157382203:web:1c0f732eee7d23b7ad201e",
    },
    giftcard: {
      serviceUrl: "https://api.brandbank.com.au/give-x/give-x",
      giveX: "https://givex-giftcards.azurewebsites.net",
    },
    reactify: {
      searchConfig: "https://config.search.reactify.app?shop=fine-day-shop.myshopify.com",
    },
    sanity: {
      dataset: "production",
    },
    shopify: {
      metafieldId: "25214086447326",
    },
    emarsys: {
      competitionFormID: "2652",
      registerFormID: "2101",
    },
    stock: {
      serviceUrl: "https://api.brandbank.com.au/inventory/v2/findinstore",
    },
    alertMe: {
      serviceUrl: "https://alertmerestockalerts.herokuapp.com/addemail",
    },
    yotpo: {
      appKey: "0vdkQp9ioAx44mZW98j6o14JVZh6lyLEGD9rN91s",
    },
  },
}
