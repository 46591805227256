module.exports = {
  app: {
    url: "https://staging.fine-day.com",
    domain: "staging.fine-day.com",
  },
  store: {
    siteCurrencyCode: "AUD",
    siteLocation: "AU",
    siteCountries: ["AU"],
    shopName: "fine-day-shop-development",
    shopNameUrl: "fine-day-shop-development.myshopify.com",
    shopDomain: "checkout.staging.fine-day.com",
    shopAccessToken: "af6778dc08f41c7223b50ca94d4e8df6",
    shopifyPlus: false,
  },
  services: {
    email: {
      template: "FDAU-WISHLIST-DFT",
      from: "service@fine-day.com",
      service: "postmark",
    },
    firebase: {
      region: "australia-southeast1",
      apiKey: "AIzaSyDzUy3GPQ-yzisoXBLa4OzBTl0YMrwakWQ",
      authDomain: "fineday-website-stage.firebaseapp.com",
      databaseURL: "https://fineday-website-stage.firebaseio.com",
      projectId: "fineday-website-stage",
      storageBucket: "fineday-website-stage.appspot.com",
      messagingSenderId: "317687685794",
      appId: "1:317687685794:web:679666c5628b1ed7906dfb",
    },
    giftcard: {
      serviceUrl: "https://devapi.brandbank.com.au/give-x/give-x",
      giveX: "https://givex-giftcards-staging.azurewebsites.net",
    },
    reactify: {
      searchConfig: "https://config.search.reactify.app?shop=fine-day-shop-development.myshopify.com",
    },
    sanity: {
      dataset: "staging",
    },
    shopify: {
      metafieldId: "24126609129693",
    },
    stock: {
      serviceUrl: "https://devapi.brandbank.com.au/inventory/v2/findinstore",
    },
    alertMe: {
      serviceUrl: "https://alertmerestockalerts.herokuapp.com/addemail",
    },
    yotpo: {
      appKey: "sU2wSW2McVKnT2dqKVS4oUuADdo5wiz6kHNDeXMb",
    },
  },
}
